import { client } from '#api/common';
import config from '#config';
import cookies from '#utils/cookies';
import { captureException } from '@sentry/react';

export const getIpLookup = async (callback: (iso: string) => void) => {
  try {
    const { data } = await client.get('/visit/info?lookup=true');
    const cookieAccept = 'cookies_agree';
    const body = document.querySelector('body');
    const cookieElement = document.querySelector('.cookie-info');

    if (!data) {
      captureException('Error while fetching Geo IP lookup data');
      console.error('Error while fetching Geo IP lookup data');
    }

    if (data) {
      const { fullInfo } = data.data || {};
      const { iso } = fullInfo || {};

      callback(iso);

      if (iso && body) {
        if (
          !config.NO_COOKIE_BANNER_COUNTRIES.includes(iso) &&
          !cookies.get(cookieAccept) &&
          cookieElement
        ) {
          cookieElement.setAttribute('style', 'display: block');
        }
        body.setAttribute('data-country', iso);
        localStorage.setItem('country', iso);
      }
    }
  } catch (error) {
    captureException(`Geo IP lookup data: ${error}`);
    console.error(`Geo IP lookup data: ${error}`);
    callback(cookies.get('_country') || 'US');
  }
};
